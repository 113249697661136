@import "../../global_style/variables";

.logo {
    width: 20rem;
}


@media (max-width: $mobile-breakpoint) {
    .logo {
        width: 15rem;
    }
}