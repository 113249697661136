$colors-primary: #203864;
$colors-primary-bright: hsla(205, 52%, 40%, 1);
$colors-blue: #4a90e2;
$colors-red: red;
$colors-red-faded: hsl(0, 100%, 94%);
$colors-orange: #da9422;
$colors-green: #029959;
$colors-alert: #fb5c5c;
$colors-black: black;
$colors-white: white;
$white-20-opacity: hsla(255, 100%, 100%, 0.1);

$colors-secondary: #53c68c;
$colors-grey-03: hsla(200, 50%, 98%, 1);
$colors-grey-05: #eef4f7;
$colors-grey-07: #ebf0f2;
$colors-grey-10: hsl(208, 21%, 88%);
$colors-grey-20: hsl(210, 16%, 76%);
$colors-grey-30: hsl(208, 12%, 58%);
$colors-grey-40: hsl(207, 12%, 43%);
$colors-grey-50: hsl(209, 15%, 28%);
$colors-grey-60: hsl(207, 29%, 21%);
$colors-grey-90: hsl(207, 76%, 3%);

$colors-green-50: #ECFDF5;
$colors-green-100: #D1FAE5;
$colors-green-200: #A7F3D0;
$colors-green-300: #6EE7B7;
$colors-green-400: #34D399;
$colors-green-500: #10B981;
$colors-green-600: #059669;
$colors-green-700: #047857;
$colors-green-800: #065F46;
$colors-green-900: #064E3B;


$box-shadow-01: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
  0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1);
$box-shadow-02: 0 3px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);

$bottom-mobile: 70px;

$mobile-breakpoint: 700px;
$tablet-breakpoint: 960px;

$mobile-overlay-height: 300px;
