@import "../../global_style/variables";

.roundButton {
  margin: 1rem;
  border-radius: 20px;
  background-color: $colors-secondary;
  color: white;
  padding: 10px 15px;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
}

.bigRoundButton {
  border-radius: 20px;
  background-color: $colors-primary;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
}

.iconButton {
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #979797;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  width: 100%;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
}

.iconButtonText {
  overflow: hidden;
  white-space: nowrap;
}


.actionButton {
  color: $colors-blue;
  font-weight: bold;
  font-size: 15px;
  padding: 12px;
  margin-bottom: 0;
  cursor: pointer;
}