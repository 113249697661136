@import "../../global_style/variables";

.gunshotItem {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  width: 100%;
}

.overview {
  display: flex;
  justify-content: space-between;
}

.list {
  max-height: 500px;
  min-height: 400px;
  overflow-y: auto;
  width: 100%;
}
@media (max-height: $tablet-breakpoint)  and (max-width: $mobile-breakpoint) {
  .list {
    max-height: 300px;
  }
}

@media (max-width: $mobile-breakpoint) {
  .list {
    width: 100%;
  }
}

.minimalLabel {
  font-size: 11px;
  color: $colors-green;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 5px;
}

.dateHeader {
  font-size: 12px;
  font-weight: bold;
  color: $colors-primary;
  margin: 0;
  padding: 20px 20px 0 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
}

.gunshotItemRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gunshotDetailRow {
margin-top: 10px;
}

.classification {
  display: flex;
  
  p {
    margin: 0;
  }
  p:last-child {
    opacity: 0.8;
    margin-left: 0.2rem;
  } 
  margin-bottom: 0.2rem;
}

.ok {
  color: $colors-orange;
}
.good {
  color: $colors-green;
}

.weak {
  color: $colors-red;
}

.match {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.8rem;
}

.category {
  color: $colors-primary;
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0.1rem;
}

.weaponTypeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // padding: 0.5rem 1rem;
  // border: 1px solid $colors-grey-10;
  // background-color: $colors-grey-07;
  border-radius: 5px;
  align-self: center;
  min-width: 8rem;
  div {
    flex-wrap: wrap;

  }
}


