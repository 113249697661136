@import "../../global_style/variables";

.userWidget {
  display: flex;
  margin-left: 0.5rem;
  align-items: center;
  color: $colors-grey-05;
  svg {
    fill: $colors-grey-05;
  }
  &:hover {
    color: $colors-grey-20;
    svg {
      fill: $colors-grey-20;
    }
  }
  padding-right: 1rem;
  @media (max-width: $mobile-breakpoint) {
    padding-right: 0.25rem;
  }
  cursor: pointer;
}

.name {
  margin-left: 0.5rem;
  text-decoration: none;
  // font-family: Roboto, sans-serif;
  font-family: "Titillium Web", sans-serif;
  font-size: 0.9rem;
  font-weight: bold;
}

@media (min-width: $mobile-breakpoint) {
  .userWidget {
    // display: none;
  }
}
