.container {
  margin-top: 10px;
}

.progressBar {
  width: 130px;
  height: 20px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.container {
  display: flex;
  align-items: center;
}

.label {
    font-size: 0.8rem;
    font-weight: bold;
}