@import "../../global_style/variables";
.relativeContainer{
  position: relative;
  margin-top: 0rem;
  
  height: 4rem;
}

.items {
position:absolute;
background-color: white;
border-radius: 5px;
margin-left: 1rem;
margin-top: 2rem;
box-shadow: $box-shadow-01;
z-index: 10;
}

.item {
  padding: 0.75rem 1rem ;
  cursor: pointer;
  &:hover {
    background-color: $colors-grey-05;
  }
}


.styleTwo {
    border: 1px solid #8795a1;
    border-radius: 5px;
}

.selected {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  
  
  cursor: pointer;

  p {
    font-weight: bold;
    color: $colors-grey-40;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0.5rem;
    margin-right: 0.25rem;
    font-size: 1rem;
  }
  div {
    margin-bottom: 1;
  }
}