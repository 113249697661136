@import '../../global_style/variables';

.planningPage {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.header {
  display: flex;
  color: white;
  z-index: 1;
  height: 65px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.padding {
  padding: 30px 15px;

}

.paddingSides {
  padding: 0 15px 15px 15px;
}


.subHeadline {
  font-size: 15px;
  font-weight: bold;
}

.planRow {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.planRowNoMargin {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.planItem {
  font-weight: bold;
  font-size: 13px;
  margin: 0;
}

.titleName {
  font-size: 16px;
  color: $colors-secondary;
  font-weight: bold;
  border: 0px;
}

.normalForm {
  font-size: 13px;
  color: $colors-grey-90;
  font-weight: bold;
  padding: 0.5rem;
  border: 1px solid $colors-grey-30;
  border-radius: 5px;
}

.formCenter {
  text-align: center;
}

.untitledPlanItem {
  font-weight: bold;
  color: $colors-grey-40;
  font-size: 13px;
  font-style: italic;
  margin: 0;
}

.planItemDesc {
  color: $colors-grey-40;
  font-size: 13px;
  margin: 0;
}

.spreadRow {
  display: flex;
  justify-content: space-around;
}



.summaryItemTitle {
  font-family: "Titillium Web", sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: $colors-primary;
}

.summaryItemValue {
  font-size: 30px;
  font-weight: bold;
  color: $colors-primary;
}

.summaryItemValueSpan {
  font-size: 15px;
  font-weight: bold;
  color: $colors-primary;
}

.noCoverage {
  font-style: italic;
  opacity: 0.6;
  font-size: 12px;
}

.navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.warnings {
  margin-top: 20px;
  text-align: center;
  font-size: 13px;
  color: $colors-orange;
  font-weight: bold;
  cursor: pointer;
}

.warningDetail {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.8;
}

.noIssues {
  margin-top: 20px;
  font-size: 13px;
  color: $colors-green;
  font-weight: bold;
  cursor: pointer;
}


.overlayFirstPageLoad {
  animation: fadeIn 1s ease forwards;
  animation-delay: 5s;
}

.planScoutRow {
  display: flex;
  align-items: center;
}

.planScoutButtonTxt {
  margin-bottom: 0;
  margin-left: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -5%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}