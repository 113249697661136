@import "../../global_style/variables";

.dropdown {
  width: 400px;
}

.dropdownScout {
  width: 400px;
}

@media (max-width: $mobile-breakpoint) {
  .dropdown {
    width: 100%;
  }
  .dropdownScout {
    width: 100%;
  }
}

.genericDropdown {
  right: 0;
  background-color: #fff;
  position: absolute;
  bottom: 125px;
  z-index: 3;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

@media (max-width: $mobile-breakpoint) {
  .genericDropdown {
    bottom: $bottom-mobile;
    max-width: none;
    width: 100%;
  }
}

.warningBox {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #fff;
  z-index: 3;
  overflow-y: auto;
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
}

@media (max-width: $mobile-breakpoint) {
  .warningBox {
    // top: 62px;
    max-width: none;
    width: 100%;
  }
}

.open {
  max-height: 500px;
}

@media (max-width: $mobile-breakpoint) {
  .open {
    max-height: $mobile-overlay-height;
  }
}

.closed {
  max-height: 0;
}

.button {
  display: inline-block;
  background-color: #fff;
  z-index: 3;
  border-radius: 20px;
  padding-left: 7px;
  padding-right: 7px;
  cursor: pointer;
}

.normalButton {
  background-color: #fff;
  z-index: 3;
  border-radius: 20px;
  padding-left: 7px;
  padding-right: 7px;
  cursor: pointer;
}

.shadow {
  box-shadow: $box-shadow-01;
}

.focusedScout {
  font-weight: 500;
  border-left: 5px solid #4a90e2;
  background: $colors-grey-05;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
  padding-left: 16px;
  color: #515151;
  cursor: pointer;
}

.dropdownPosition {
  position: absolute;
  bottom: 0;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .mapContainer {
    width: 100%;
    height: 300px;
  }

  .mapElement {
    height: 300px;
    margin: 0;
  }
}

.fadeIn {
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

