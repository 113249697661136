@import '../../global_style/variables';

.hr {
    border: 0.5px solid $colors-grey-30;
    opacity: 0.2;
    margin: 0;
  }

  .vr {
    height: 2rem;
    width: 1px;
    border: 0.5px solid $colors-grey-30; /* Adjust color as desired */
    opacity: 0.2;
    margin: auto;
  }