@import "../../global_style/variables";

.summaryLabel {
  font-family: "Titillium Web", sans-serif;
  margin: 0;
  font-weight: bold;
}

.summaryValue {
  font-family: "Titillium Web", sans-serif;
  margin: 0;
  font-size: 1.7rem;
}

@media (max-width: $mobile-breakpoint) {
  .summaryValue {
    font-size: 1.4rem;
  }
}
