@import "../../global_style/variables";

.warnings {
  position: fixed;
  z-index: 1;
  bottom: 120px;
  max-width: 40rem;
  width: 80%;
}

.warningsoverlay {
  right: 0;
}

@media (max-width: $mobile-breakpoint) {
  .warnings {
    max-width: none;
    width: 100%;
    bottom: $bottom-mobile;
  }
}

.coverageOverlay {
  position: fixed;
  right: 0;
  z-index: 1;
  bottom: 125px;
  color: #4e4e4e;
  max-width: 500px;
  width: 80%;
}

.warningList {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
}

@media (max-height: $tablet-breakpoint)  and (max-width: $mobile-breakpoint) {
  .warningList {
    max-height: 250px;
  }
}

.coverageController {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: $mobile-breakpoint) {
  .coverageOverlay {
    max-width: none;
    width: 100%;
    bottom: $bottom-mobile;
  }
}

.warning {
  padding: 15px;
  background-color: #fff;
  color: #4e4e4e;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
  p {
    margin: 0;
  }
}

.warning:last-child {
  padding-bottom: 2.5rem;
}

.hrStyle {
  width: 100%;
  border-top: 0.1px solid #eaeaea;
  margin: 30px;
}

.statusListItemStatus {
  width: 5rem;
  font-weight: bold;
}

.message {
  margin-right: 15px;
  margin-bottom: 0;
  flex-grow: 1;
  padding-right: 1rem;
}

.activeScout {
  margin-top: 30px;
  margin-bottom: 20px;
}

.fadeInOverlay {
  color: black;
  right: auto;
  width: 100%;
  top: 4rem;
  position: absolute;
  z-index: 3;
}

.warningMsg {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: $box-shadow-01;
  max-width: 100rem;
  padding: 0.5rem 2rem 0.5rem 1rem;
  
  
  p {
    margin: 0;
    margin-top: 0.125rem;
    margin-left: 0.5rem;
    font-size: 1rem;
    color: $colors-primary;
    font-weight: 500;
  }
  
}

.warningMsgWrapper{
  display: inline-block;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: $mobile-breakpoint) {

  .fadeInOverlay {
    top: 3.75rem;
  }
  .warningMsg {
    width: 100%;
    border-radius: 0;


  }
  .warningMsgWrapper{
    display: block;
    margin-left: 0;
    transform: translateX(0%);
    margin-bottom: 0rem;
  }
}

.show {
  animation: fullOpacity 3s ease-in-out;
  opacity: 0;
}

.hide {
  opacity: 0;
  display: none;
}

@keyframes fullOpacity {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}
