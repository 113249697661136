.centerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    animation: opacityfadein .6s;
}

.dot {
    text-align: center;
    animation: dot-keyframes 1s infinite ease-in-out;

    border-radius: 10px;
    display: inline-block;
    height: 15px;
    width: 15px;
    opacity: .4;
    margin: 2px;

}

.first {
    animation-delay: .3s;
}

.second {
    animation-delay: .6s;
}

.loadingScreen {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-left:30px;
    padding-right:30px;
    background-color: white;
    color: #515151;
    padding-top: 20px;
    max-width: 400px;
    height: 350px;
    width: 100%;
}

.logoWrapper {
    margin-bottom: 1rem;
}

.fullLoadingScreen {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    position:fixed;
    z-index: 100;
    top:50%;
    left:50%;
    margin:-100px 0 0 -100px;
    width:200px;
    height:200px;
}

.loadingMessage {
    font-weight: normal;
    margin-bottom: 30px;
}

.content {
    display: flex;
    flex-direction: column;
}

@keyframes opacityfadein {
    0% { opacity: 0; }
    30% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes dot-keyframes {
    0% {
        opacity: .4;
        transform: scale(1, 1);
    }

    50% {
        opacity: 1;
        transform: scale(1.2, 1.2);
    }

    100% {
        opacity: .4;
        transform: scale(1, 1);
    }
}
.bigLoader {
    width: 12em;
    height: 12em;
    font-size: 4px;
}

.smallLoader {
    width: 5em;
    height: 5em;
    font-size: 5px;
}

.vanillaLoaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.bigLoader, .smallLoader {
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid white;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.bigSpinner {
    margin-top: 3rem;
    width: 4em;
    height: 1.5em;
    position: relative;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
