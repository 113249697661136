@import "../../global_style/variables";

.notificationBox {
    box-shadow: 0 0.25rem 0.75rem $colors-grey-10;
    border-radius: 4px;
    color: white;
    font-size: 1.25rem;
    font-weight: bold;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 1rem 2rem;
    margin: 2rem 0;
  }