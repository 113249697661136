@import "../../global_style/variables";

.lightInfo {
  color: #8d8d8d;
}

.container {
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
}


.regular {
  font-weight: normal;
}

.thin {
  font-weight: lighter;
}

.logo {
  width: 170px;
  margin-bottom: 20px;
}

.centerText {
  text-align: center;
}

.noMargin {
  margin: 0;
}

.link {
  color: #4a90e2;
}

.item {
  font-size: 0.8rem;
  font-weight: 500;
  margin-right: 5px;
  border-radius: 36px;
  padding: 3px 7px 3px 7px;
  border: 1px solid $colors-grey-10;
}

.padding {
  padding: 0.5rem;
}

.paddingVertical {
  padding: 1rem;
}

.row {
  display: flex;
}

.smallHeader {
  font-weight: bold;
  font-size: 1.2em;
}

.openAnimation {
  animation: fadeinout 0.3s linear;
  overflow-y: hidden;
}

.closeAnimation {
  animation: fadeout 0.15s linear;
  overflow-y: hidden;
}

@media (max-width: $mobile-breakpoint) {
  .openAnimation {
    animation: fadeinoutMobile 0.15s linear;
  }

  .closeAnimation {
    animation: fadeoutMobile 0.1s linear;
  }
}

.closedOverlay {
  height: 0;
}

.clickable {
  cursor: pointer;
}

.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.itemAnimation {
  opacity: 0;
  animation: fadeIn 0.3s ease-in both;
}

.fadeinContent {
  opacity: 0;
  animation: fadeInWithDelay 0.6s ease-in both;
}

.thinHeader {
  font-size: 16px;
  color: $colors-primary;
}

.primaryBold {
  font-size: 1rem;
  color: $colors-grey-50;
}

.primarySm {
  font-size: 0.8rem;
  color: $colors-grey-50;
}

.primary {
  font-size: 1rem;
  color: $colors-grey-50;
}

.primarySmBold {
  font-size: 0.8rem;
  font-weight: bold;
  color: $colors-grey-50;
}


.secondaryBold {
  font-size: 1rem;
  color: $colors-grey-40;
}

.secondarySm {
  font-size: 0.8rem;
  color: $colors-grey-40;
}

.secondary {
  font-size: 1rem;
  color: $colors-grey-40;
}

.secondarySmBold {
  font-size: 0.8rem;
  font-weight: bold;
  color: $colors-grey-40;
}

.subHeader {
  font-size: 0.8rem;
  color: $colors-grey-50;
  font-weight: bold;
  margin-top: 0.2em;
  margin-bottom: 0.3em;
}



.actionText {
  font-size: 14px;
  margin: 0;
  font-weight: bold;
  color: #203864;
  cursor: pointer;
}

.editFieldLabel {
  font-size: 0.75rem;
  margin-bottom: 0.125rem;
  margin-left: 0.5rem;
  font-weight: bold;
  opacity: 0.8;
  color: #203864;
}

.infoText {
  font-size: 0.8rem;
  color: $colors-grey-60;
  font-weight: 500;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
  span {
    font-weight: bold;
  }
}

.verCenteredRow {
  display: flex;
  align-items: center;
}

.flexStartRow {
  display: flex;
  justify-items: flex-start;
  align-items: center;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.summaryItemTitle {
  font-family: "Titillium Web", sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: $colors-primary;
}

.option {
  color: $colors-blue;
  font-weight: bold;
  font-size: 15px;
  padding: 12px;
  cursor: pointer;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.overlayAnimation {
  animation: fadeIn 1s ease forwards;
}

.planningOverview {
  border-radius: 11px;
  background-color: white;
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
  width: 290px;
}

.oneItemPadding {
  padding: 15px 30px;
}

.overlay {
  position: absolute;
  top: 75px;
  right: 10px;
  z-index: 2;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -5%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInWithDelay {
  0% {
    opacity: 0;
    transform: translate3d(0, -2%, 0);
  }
  50% {
    opacity: 0;
    transform: translate3d(0, -2%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -5%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeinout {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 500px;
  }
}

@keyframes fadeout {
  0% {
    max-height: 500px;
  }
  100% {
    max-height: 0;
  }
}

@keyframes fadeinoutMobile {
  0% {
    max-height: 0;
  }
  100% {
    max-height: $mobile-overlay-height;
  }
}

@keyframes fadeoutMobile {
  0% {
    max-height: $mobile-overlay-height;
  }
  100% {
    max-height: 0;
  }
}
