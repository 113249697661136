@import '../../global_style/variables';

.buttonText {
    overflow-x: hidden;
    font-weight: bold;
    color: $colors-grey-40;
}

.darkButton {
    padding: 7px 4px;
    display: flex;
    flex-direction: row;
}

.geocode {
    z-index: 5;
}

.buttonGroup {
    margin-top: 0.5rem;
    margin-left: 1rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}