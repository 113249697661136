@import "../../global_style/variables";

.scoutList {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
}

@media (max-height: $tablet-breakpoint)  and (max-width: $mobile-breakpoint) {
  .scoutList {
    max-height: 250px;
  }
}

@media (max-width: $mobile-breakpoint) {
  .scoutList {
    width: 100%;
  }
}

.scoutInformation {
  // margin: 20px 15px;
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  color: #515151;
  cursor: pointer;
}

.itemInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.simpleRow {
  display: flex;
  flex-direction: row;
}

.centered {
  align-items: center;
}

.start {
  align-items: flex-start;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5px;
  margin-bottom: 5px;
}

.scoutItemDetails {
  flex-grow: 1;
  margin-left: 1rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.spacedRow {
  display: flex;
  justify-content: space-between;
}

.displayName {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 0.9rem;
  font-weight: bold;
}

.content {
  margin-left: 0;
}

.overview {
  display: flex;
  justify-content: space-between;
}

.controllers {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
