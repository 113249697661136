@import "../../global_style/variables";

.container {
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.device {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.wizardContainer {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  background-color: white;
  color: #515151;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: $mobile-breakpoint) {
  .wizardContainer {
    height: calc(100vh - 60px);
    max-width: 400px;
  }
  .titleAndWizard {
    max-width: 400px;
  }
}

.connectedDevicesList {
  overflow-y: scroll;
  flex-grow: 2;
}

@media (min-height: $mobile-breakpoint) {
  .connectedDevicesList {
    height: 300px;
  }
  }

.setupDevices {
  display: flex;
  flex-direction: column;
  width: 100%;;
}

.titleAndWizard {
  width: 100%;
}

.organisationForm {
  background-color: white;
  color: #515151;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  max-width: 400px;
  height: 350px;
}

.setupPage {
  color: white;
  display: flex;
  justify-content: center;
}

.organisationPage {
  margin-top: 40px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thin {
  font-weight: normal;
}

.code {
  color: $colors-primary;
  font-size: 2.5em;
  a {
    text-decoration: none;
    color: $colors-primary;
  }
}

.description {
  text-align: center;
}

.spinner {
  color: $colors-primary;
  margin-top: 20px;
}

.organisationTextField {
  font-size: 20px;
  padding: 10px;
  width: 300px;
  margin-top: 10px;
  margin-bottom: 30px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.user {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 30px;
}

.loadingCode {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
