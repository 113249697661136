.content{
  color: white;
  font-size: 20px;
  padding-top: 0px;
  padding-bottom: 5px;
  opacity: 0;
}

.name{
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}