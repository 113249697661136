
.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 10%;
}

.loginForm {
    margin-top: 2rem;
    padding: 10px 30px 40px;
    border-radius: 3px !important;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.title {
    font-weight: normal;
    font-size: 35px;
    color: #444;
    margin-bottom: 20px;
}

.errorDesc {
    font-weight: normal;
    font-size: 35px;
    color: #fff;
    margin-bottom: 20px;
}

.authContainer {
    position: relative;
    width: 100%;
}

