@import "../../global_style/variables";

.summaryNotificationOverlay {
  display: flex;
  justify-content: space-around;
  height: 28px;
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  color: $colors-black;
  background-color: $colors-white;
  border-radius: 20px;
  background-color: $colors-red-faded;
  padding-left: 12px;
  padding-right: 14px;
}

.notificationText {
  font-size: 0.8rem;
  font-weight: bold;
  opacity: 0.9;
  color: $colors-grey-60;
}

.newShotIcon {
  padding-top: 0.1rem;
  padding-right: 0.2rem;
}

@media (max-width: $mobile-breakpoint) {
  .summaryNotificationOverlay {
    height: 100%;
    padding-top: 0.15rem;
    padding-bottom: 0.15rem;
    margin-bottom: -1rem;
  }
  .newShotIcon {
    display: none;
  }
  .notificationText {
    font-size: 0.7rem;
  }
}

.icon {
  margin-left: 5px;
  margin-right: 15px;
  color: $colors-alert;
}

.text {
  white-space: nowrap;
  font-weight: bold;
}

.normalText {
  white-space: nowrap;
  font-weight: normal;
}

.secondText {
  font-size: 10px;
  font-weight: lighter;
}

.notificationBlink {
  animation: blinker-background 3s linear;
}

.invNotificationBlink {
  animation: inv-blinker-color 3s linear;
}

.notificationExpand {
  display: flex;
  justify-content: center;
}

.loadingOverlay {
  overflow-x: hidden;
  font-size: 0.8rem;
  font-weight: bold;
  color: $colors-grey-50;
  padding: 7px 10px;
  display: flex;
  flex-direction: row;
}
.loadingContent {
  display: flex;
  position: relative;
  padding-left: 25px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.loadingSpinner {
  position: absolute;
  top: -40px;
  left: 7px;
}

@keyframes blinker-background {
  0% {
    background: white;
  }
  20% {
    background: $colors-alert;
  }
  40% {
    background: white;
  }
  60% {
    background: $colors-alert;
  }
  80% {
    background: $colors-alert;
  }
  100% {
    background: white;
  }
}

@keyframes inv-blinker-color {
  0% {
    color: $colors-alert;
  }
  20% {
    color: white;
  }
  40% {
    color: $colors-alert;
  }
  60% {
    color: white;
  }
  80% {
    color: white;
  }
  100% {
    color: $colors-alert;
  }
}
