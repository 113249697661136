@import "../../global_style/variables";

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: absolute;
  bottom: -2px;
  right: 0;
  background: white;
  z-index: 5;
  border-top-left-radius: 20px;
  padding-right: 2rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  width: 650px;
}
@media only screen and (max-width: $mobile-breakpoint) {
  .content {
    width: 100%;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
  }
}

.summary {
  display: flex;
  justify-content: space-around;
  flex-grow: 2;
  color: $colors-primary;
  z-index: 5;
}
