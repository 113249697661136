@import "../../global_style/variables";

.content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.overviewPage {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.summary {
  display: flex;
  justify-content: space-around;
  flex-grow: 2;
  color: white;
  z-index: 1;
  max-width: 1000px;
}

.statusDot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  margin-bottom: 3px;
}

.status {
  width: 100%;
}

.menuItem {
  color: black;
}

.menuLink {
  color: #203864;
  font-size: 1em;
  font-weight: bold;
  margin-top: 14px;
}

.selected {
  background: $colors-green-500;
  color: white !important;
  // font-weight: bold;
  border-radius: 20px;
}

.subsection {
  color: $colors-primary;
  font-size: 1.25rem;
}

.notSelected {
  color: $colors-secondary;
  background: #f3f4f6;
  border-radius: 20px;
}

.selection {
  padding: 0.25rem 0.75rem;
  color: $colors-green-500;
  margin-left: 7px;
  cursor: pointer;
}

.sectionGroup {
  display: flex;
  align-items: center;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 400px) {
  .status {
    width: 80px;
  }
}

.focus {
  flex-grow: 1;
  background: $colors-grey-05;
  border-radius: 15px;
  color: $colors-primary;
  // border-bottom: 5px solid #fff;
  padding-left: 10px;
  padding-right: 10px;
}

.noFocus {
  flex-grow: 1;
  // border-bottom: 5px solid $colors-primary;
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .noFocus {
    padding-left: 1px;
    padding-right: 1px;
  }
  .focus {
    padding-left: 1px;
    padding-right: 1px;
    border-radius: 0;
  }
}

.fadeBackground {
  background: black;
  position: fixed;
  opacity: 0.4;
  height: calc(100vh - 120px);
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
}

.text {
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
  cursor: pointer;
}

.logoWithUserText {
  font-size: 14px;
  opacity: 0.8;
}

.iconAndText {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}

.logoAndText {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.padding {
  padding-top: 16px;
  padding-bottom: 14px;
}


@media only screen and (max-width: $mobile-breakpoint) {
  .padding {
    padding-top: 13px;
    padding-bottom: 13px;
    line-height: 1.1;
    width: 100%;
  }
}

.textAndIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .logoWithUserText {
    margin: 0;
  }
}

.mobileHeaderRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.menuItems {
  ul {
    list-style-type: none;
    display: flex;

    padding-right: 1rem;
  }
  .link {
    color: $colors-grey-05;
    text-decoration: none;
    font-family: "Titillium Web", sans-serif;
    cursor: pointer;
    margin-right: 1.5rem;
    font-size: 0.9rem;
    font-weight: bold;
    &:hover {
      color: $colors-grey-20;
    }
  }
}

.coverageOverlay {
  position: fixed;
  z-index: 15;
  bottom: 125px;
  color: #4e4e4e;
  max-width: 500px;
  width: 80%;
  left: 0;
}

.menuIcon{
  margin-right: 1.25rem;
  @media only screen and (max-width: $mobile-breakpoint) {
    margin-right: .25rem;
  }
}

.menuOverlay {
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  z-index: 10;
  position: absolute;
  background: white;
  max-width: 500px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}
@media (max-width: 800px) {
  .menuOverlay {
    max-width: 100%;
  }
}

.planningMenu {
  position: fixed;
  z-index: 15;
  top: 65px;
  bottom: 0;
  left: 0;
  right: 0;
  color: #4e4e4e;
  background: white;
  overflow-y: auto;
  max-width: 500px;
  width: 80%;
}

.summaryDescription {
  font-size: 0.9rem;
}
@media (max-width: $mobile-breakpoint) {
  .summaryDescription {
    font-size: 0.6rem;
  }
}

@media (max-width: $mobile-breakpoint) {
  .coverageOverlay {
    // top: 62px;
    max-width: none;
    width: 100%;
  }
  .planningMenu {
    top: 62px;
    max-width: none;
    width: 100%;
  }
}

.shotSummary {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: $mobile-breakpoint) {
  .user {
    margin-left: 0px;
  }
}

@media (max-width: $mobile-breakpoint) {
  .summaryText {
    font-size: 0.7em;
  }
}

@keyframes fadeinout {
  0% {
    background: #fff;
  }
  20% {
    background: #ec434375;
  }
  50% {
    background: #ec434375;
  }
  90% {
    background: #fff;
  }
  100% {
    background: #fff;
  }
}
