@import '../../global_style/variables';

.primaryInfo {
    color: #4e4e4e;
    font-size: 14px;
    font-weight: normal;
    margin-top: 0.2em;
    margin-bottom: 0.3em;
}

.lightInfo {
    font-size: 0.9em;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    color: $colors-grey-30;
}

.mediumLightInfo {
    //font-size: 0.8em;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    color: $colors-grey-40;
}