.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.menu {
  display: flex;
  justify-content: flex-start;
  padding-left: 1rem;
}
