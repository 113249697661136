@import '../../global_style/variables';

.logo {
    color: $colors-white;
    cursor: pointer;
    font-size: 64px;
    font-weight: 600;
    letter-spacing: -3px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.smallLogo {
    color: $colors-white;
    cursor: pointer;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -1px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.planningStyle{
    color: #CB7428;
    margin-left: 0.2rem;
    margin-bottom: 2px;
    
}

.logoContent {
    display: flex;
    align-items: flex-end;
}