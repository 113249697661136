@import "../../global_style/variables";


.form {
    flex-basis: 25rem;
    flex-grow: 1;
    border: 1px solid $colors-grey-10;
    border-radius: 3px;
    margin: 1rem 1rem;
    padding: 1rem 2rem 1.5rem;
}

.header {
    color: $colors-primary;
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.description {
    color: $colors-grey-40;
    margin-bottom: 2rem;
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
.error {
    color: red;
    max-width: 300px;
    text-align: center;
}
  .formTextField {
    font-size: 20px;
    padding: 1rem;
    width: 300px;
    margin-top: 1rem;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  