@import "../../global_style/variables";

.container {
  background: white;
  flex-grow: 1;
}
.content {
  max-width: 57rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.criticalItem {
    background: white;
    // box-shadow: 0 0.25rem 0.75rem $colors-grey-10;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 1rem 2rem;
    margin-bottom: 0.5rem;
    font-size: 1.125rem;
}

.criticalItem:last-child {
  margin-bottom: 10rem;
}

.criticalPage {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
}

.statusLink {
  color: black;
  opacity: 0.6;
  font-weight: bold;
  margin: 2rem 2rem;
}

.label {
  font-weight: bold;
  opacity: 0.5;
  display: inline-block;
  font-size: 1rem;
  margin-bottom: 0;
}
