@import '../../global_style/variables';

.label {
    background: $colors-grey-05;
    color: $colors-grey-50;
    border-radius: 15px;
    padding: 1px 7px;
    font-size: 0.7rem;
    margin-left: -7px;
    margin-bottom: 3px;
    display: inline-block;
}

.bigLabel {
    background: $colors-grey-05;
    border-radius: 24px;
    color: $colors-primary;
    padding: 3px 10px;
    font-size: 1rem;
    display: inline-block;
    // font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;

}


.circle {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: lighter;
    display: flex;
    justify-content: center;
    align-items: center;
}
