@import "../../global_style/variables";

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: $colors-grey-20;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin-left: 10px;
  margin-right: 10px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $colors-primary;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $colors-primary;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sliderItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}

.sliderRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sliderDescription {
  line-height: 0.5;
  color: #a9a9a9;
}

.secondaryText {
  font-size: 14px;
}

.sliderOverlay {
  position: absolute;
  z-index: 3;
  bottom: 23px;
  right: 630px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sliderElem {
  width: 300px;
  margin-right: 20px;
}

.infoAndSlider {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
}

@media (min-width: 1400px) {
  .sliderOverlay {
    width: 850px;
    right: 700px;
  }

  .sliderElem {
    width: 800px;
  }
  .infoAndSlider {
    width: 850px;
  }
}

@media (max-width: $mobile-breakpoint) {
  .sliderOverlay {
    right: 0;
    bottom: 0;
    padding-top: 5px;
    padding-bottom: 15px;
    background: white;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.sliderInfo {
  color: #6f6f6f;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  max-width: 400px;
  font-weight: bold;
  font-size: 0.8rem;
  margin: 2px;
  padding: 5px;
}

.pressedAnimation {
  animation: increasefont 0.3s forwards;
}

.releasedAnimation {
  animation: decreasefont 0.2s forwards;
}

.progressContainer {
  margin-bottom: -5px;
  width: 500px;
  text-align: center;
}

.progress2 {
  padding: 4px;
  border-radius: 30px;
  background: rgb(255, 255, 255);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.infoBox {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #000;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  width: 400px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
  text-align: center;
}

.progressMoved .progressBar2 {
  width: 100%;
  background-color: $colors-primary;
  animation: progressAnimation linear 20s;
}

.progressBar2 {
  height: 18px;
  border-radius: 30px;
  background-color: $colors-primary;

  transition: 0.4s linear;
  transition-property: width, background-color;
}

.dragDescription {
  font-size: 13px;
  font-weight: bold;
}

@keyframes progressAnimation {
  0% {
    width: 5%;
  }
  100% {
    width: 100%;
  }
}

@keyframes increasefont {
  from {
    font-size: 0.8rem;
  }
  to {
    font-size: 1rem;
    padding: 5px 10px;
    margin-bottom: 20px;
  }
}

@keyframes decreasefont {
  from {
    font-size: 1rem;
    padding: 5px 10px;
    margin-bottom: 10px;
  }
  to {
    font-size: 0.8rem;
  }
}
